import Avatar from "../../components/Shared/Avatar";
import { getFullName, renderImage } from "../../Helpers/utils";
import dayjs from "../../lib/dayjs";
import ActionsDropDown from "./ActionsDropDown";
import TimeDifference from "./TimeDifference";


export default function AppointmentCards({ appo, select, t }) {
    const { booking_date, booking_time, booking_status, client, booking_key } = appo;

    const appoDate = dayjs(`${dayjs(booking_date).format('YYYY-MM-DD')} ${booking_time}`);

    const formatAppointmentDate = (d) => {
        const now = dayjs();
        const isToday = d.isSame(now, 'day');
        const isTomorrow = d.isSame(now.add(1, 'day'), 'day');

        const timeFormat = d.format('HH:mm A');
        const dateFormat = d.format('dddd MMM DD');
        const yearFormat = d.format('YYYY');

        if (isToday) {
            return t('Today at {{time}}', { time: timeFormat });
        }

        if (isTomorrow) {
            return t('Tomorrow at {{time}}', { time: timeFormat });
        }

        if (d.isSame(now, 'year')) {
            return t('{{date}} at {{time}}', { date: dateFormat, time: timeFormat });
        }

        return t('{{date}} {{year}} at {{time}}', {
            date: dateFormat,
            year: yearFormat,
            time: timeFormat,
        });
    };

    return (
        <div onClick={() => select(booking_key)} className="lg:py-4 py-3 lg:px-4 px-3 w-full flex justify-between items-center bg-white border border-borderColor/50 hover:border-borderColor rounded-lg transition duration-200 select-none cursor-pointer">
            <div className="flex items-start gap-2">
                <div>
                    <Avatar
                        src={client?.avatar ? renderImage(client.avatar) : ''}
                        size="lg:w-11 lg:h-11 w-8 h-8"
                        alt={getFullName(client)}
                        className="rounded-full border-2 border-borderColor shadow-sm"
                    />
                </div>

                <div className="">
                    <span className="lg:textBody-s2 textBody-xs2 text-fontColor line-clamp-1">{getFullName(client).charAt(0).toUpperCase() + getFullName(client).slice(1)}</span>
                    <p className="lg:textBody-xs3 textBody-2xs3 text-fontColor2">{formatAppointmentDate(appoDate)}</p>
                </div>
            </div>

            <div className="rtl:dir-ltr ltr:dir-rtl">
                <ActionsDropDown
                    status={booking_status}
                />
                {['active', 'confirmed'].includes(booking_status) &&
                    <div className="inline-block">
                        <TimeDifference
                            selectedDateVal={booking_date}
                            selectedTimeVal={booking_time}
                            t={t}
                        />
                    </div>
                }
            </div>
        </div>
    );
}