import { Link } from "react-router-dom";
import { Appointments, Clients, Dashboard, Date, Salon, Services, Share } from "../../icons";
import { useTranslation } from "react-i18next";



export default function Settings() {

    const { t } = useTranslation();

    const links = [
        {
            label: t('sett_01'),
            descr: t('sett_02'),
            icon: <Dashboard className="w-4 h-4" />,
            path: '/',
            isNew: false,
        }, {
        //     label: t('sett_03'),
        //     descr: t('sett_04'),
        //     icon: <Calendar2 className="w-4 h-4" />,
        //     path: '/dashboard/calendar',
        //     isNew: false,
        // }, {
            label: t('sett_05'),
            descr: t('sett_06'),
            icon: <Appointments className="w-4 h-4" />,
            path: '/appointments',
            isNew: false,
        }, {
            label: t('sett_07'),
            descr: t('sett_08'),
            icon: <Salon className="w-4 h-4" />,
            path: '/salon',
            isNew: false,
        }, {
            label: t('sett_09'),
            descr: t('sett_10'),
            icon: <Share className="w-4 h-4" />,
            path: '/share',
            isNew: false,
        }, {
            label: t('sett_11'),
            descr: t('sett_12'),
            icon: <Date className="w-4 h-4" />,
            path: '/worktime',
            isNew: false,
        }, {
            label: t('sett_16'),
            descr: t('sett_17'),
            icon: <Services className="w-4 h-4" />,
            path: '/services',
            isNew: true,
        }, {
            label: t("cl_01"),
            descr: t('cl_02'),
            icon: <Clients className="w-4 h-4" />,
            path: '/clients',
            isNew: true,
        },
    ];

    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 p-4">
            <div className="mb-3 max-lg:mt-3 pb-3">
                <div className="flex flex-col gap-1">
                    <h2 className="flex items-center textHeader-l">{t('sett_13')}</h2>
                    <p className="textBody-s3">{t('sett_14')}</p>
                </div>
            </div>
            <div className="w-full max-w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 xl:gap-6 lg:gap-4 md:gap-3 gap-2">
                {links?.map((link, index) => {

                    const { path, icon, label, descr, isNew } = link;

                    return (
                        <Link to={path ?? '/'} key={index}>
                            <div className={`relative md:px-5 md:py-5 px-3 py-3 h-full flex flex-col gap-1 md:rounded-lg rounded-md cursor-pointer bg-white border border-borderColor/60 hover:border-borderColor`}>
                                <div className="flex flex-col gap-2">
                                    <div className="md:w-10 md:h-10 w-fit md:bg-secondary/5 rounded-full md:p-2 text-secondary flex items-center justify-center">
                                        {icon}
                                    </div>
                                    <span className="md:textBody-m2 textBody-s2 line-clamp-1 text-fontColor">{label}</span>
                                </div>
                                <p className="textBody-s3 text-fontColor2/80 max-md:hidden mb-3">{descr}</p>
                                {isNew && <span className="absolute md:top-4 md:ltr:right-4 md:rtl:left-4 top-2 ltr:right-2 rtl:left-2 textLink-2xs text-secondary py-1 px-2 bg-secondary/5 rounded-xl">{t('sett_15')}</span>}
                            </div>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}