import { Link } from "react-router-dom";
import Charts from "./Charts";
import { GetDashboardStatistics } from "../../lib/api";
import { useTranslation } from "react-i18next";


export default function Dashboard() {

    const { t } = useTranslation()

    const { data, loading } = GetDashboardStatistics();

    const { stats, appointments_by_numbers } = data?.data ?? {};

    const { all, active, confirmed, completed, canceled } = appointments_by_numbers ?? {};


    const calculatePercentage = (num) => {
        // Ensure inputs are treated as numbers
        const total = Number(all);
        const perc = Number(num);

        if (total === 0) {
            return '0%';
        }

        // Calculate the percentage
        const percentage = (perc / total) * 100;

        // Return the formatted percentage string
        return `${percentage.toFixed(2)}%`;
    }

    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 p-4">
            <div className="mb-3 max-lg:mt-3 pb-3">
                <h2 className="textHeader-s">{t("stat_01")}</h2>
            </div>
            {loading ? suspense() :
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                    <div className="bg-white p-3 rounded-lg border border-borderColor/80">
                        <Charts period={stats?.period ?? []} statsData={stats?.data ?? []} />
                    </div>

                    <div className="grid grid-cols-2 gap-2">
                        <Link to="/appointments?status=active">
                            <div className="bg-white h-full p-4 rounded-lg border border-borderColor/80 flex flex-col gap-4 justify-between hover:shadow-lg transition-all duration-200 select-none">
                                <div className="flex justify-between">
                                    <span className="textBody-xs1">{t("stat_02")}</span>
                                </div>

                                <div className="flex justify-between items-end">
                                    <span className="textBody-ml1">{active ?? 0}</span>
                                    <span className="textBody-xs2 text-green-500">{calculatePercentage(active)}</span>
                                </div>
                            </div>
                        </Link>
                        <Link to="/appointments?status=confirmed">
                            <div className="bg-white h-full p-4 rounded-lg border border-borderColor/80 flex flex-col gap-4 justify-between hover:shadow-lg transition-all duration-200 select-none">
                                <div className="flex justify-between">
                                    <span className="textBody-xs1">{t("stat_03")}</span>
                                </div>

                                <div className="flex justify-between items-end">
                                    <span className="textBody-ml1">{confirmed ?? 0}</span>
                                    <span className="textBody-xs2 text-blue-500">{calculatePercentage(confirmed)}</span>
                                </div>
                            </div>
                        </Link>
                        <Link to="/appointments?status=completed">
                            <div className="bg-white h-full p-4 rounded-lg border border-borderColor/80 flex flex-col gap-4 justify-between hover:shadow-lg transition-all duration-200 select-none">
                                <div className="flex justify-between">
                                    <span className="textBody-xs1">{t("stat_04")}</span>
                                </div>

                                <div className="flex justify-between items-end">
                                    <span className="textBody-ml1">{completed ?? 0}</span>
                                    <span className="textBody-xs2 text-primary-500">{calculatePercentage(completed)}</span>
                                </div>
                            </div>
                        </Link>
                        <Link to="/appointments?status=canceled">
                            <div className="bg-white h-full p-4 rounded-lg border border-borderColor/80 flex flex-col gap-4 justify-between hover:shadow-lg transition-all duration-200 select-none">
                                <div className="flex justify-between">
                                    <span className="textBody-xs1">{t("stat_05")}</span>
                                </div>

                                <div className="flex justify-between items-end">
                                    <span className="textBody-ml1">{canceled ?? 0}</span>
                                    <span className="textBody-xs2 text-red-500">{calculatePercentage(canceled)}</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            }
        </div>
    )
}


const suspense = () => {
    return (
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 animate-pulse">
            <div className="p-4 rounded-lg border border-borderColor/50 lg:min-h-80 md:min-h-60 min-h-40 bg-white">
            </div>

            <div className="grid grid-cols-2 gap-2">
                <div className="p-4 rounded-lg border border-borderColor/50 flex flex-col gap-4 justify-between bg-white min-h-16">
                </div>

                <div className="p-4 rounded-lg border border-borderColor/50 flex flex-col gap-4 justify-between bg-white min-h-16">
                </div>

                <div className="p-4 rounded-lg border border-borderColor/50 flex flex-col gap-4 justify-between bg-white min-h-16">
                </div>

                <div className="p-4 rounded-lg border border-borderColor/50 flex flex-col gap-4 justify-between bg-white min-h-16">
                </div>
            </div>
        </div>
    )
}