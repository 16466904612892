import { useState } from "react";
import { Hide1, PointsMenu, Spinner } from "../../../icons";
import TimeDifference from "./TimeDifference";
import ModalLayout from "../../../layouts/ModalLayout";
import { isNull } from "../../../Helpers/utils";
import { ChangeAppointmentStatus } from "../../../lib/api";
import { useAlerts } from "../../../context/AlertsContext";

export default function Footer({ t, id, date, time, status, refresh }) {

    const { addAlert } = useAlerts()

    const [showDropDown, setShowDropDown] = useState(false)
    const toggleShowDropDown = () => setShowDropDown(!showDropDown)

    const [inProgress, setInProgress] = useState('')

    const handleChangeStatus = async (s) => {

        if (s === status) {
            setShowDropDown(false);
            return;
        }

        setInProgress(s)

        const data = {
            status: s,
            appointment: id,
        };

        const { is_ok, message, type } = await ChangeAppointmentStatus(data);

        setInProgress('')

        if (type === "validation") {
            //
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            refresh();
            setShowDropDown(false);
        }
    }

    const renderButtonName = (s) => {
        switch (s) {
            case ('active'):
                return { name: "Confirmed", fnc: () => handleChangeStatus('confirmed') }
            case ('confirmed'):
                return { name: "Completed", fnc: () => handleChangeStatus('completed') }
            default:
                return { name: "Change status", fnc: () => toggleShowDropDown() }
        }
    }

    const renderAppoStatusName = (stts) => {
        switch (stts) {
            case 'Confirmed':
                return "dash_appo_9";
            case 'Completed':
                return "dash_appo_10";
            case 'Change status':
                return "appo_d_2";
            default:
                return "appo_d_2";
        }
    }

    const buttonDetails = renderButtonName(status);

    return (
        <div className="sticky bottom-0">
            {['active', 'confirmed'].includes(status) &&
                <div className=" px-4 lg:px-6 pb-2 pt-0 lg:py-4">
                    <TimeDifference
                        selectedDateVal={date}
                        selectedTimeVal={time}
                        t={t}
                    />
                </div>}

            <div className="relative bg-white lg:h-[70px] h-[60px] flex items-center justify-between px-4 lg:px-6 border-t shadow-sm">
                <div className="w-full max-w-5xl mx-auto flex items-center lg:gap-2 gap-1">
                    <button
                        onClick={() => {
                            buttonDetails?.fnc()
                        }}
                        className={`w-full outline-none whitespace-nowrap rounded-md py-3.5 px-6 transition-colors duration-200 select-none textBody-s2 border flex justify-center
                        ${['active', 'confirmed'].includes(status)
                                ? 'text-white bg-primary hover:bg-primary/90 border-primary hover:border-primary/90'
                                : 'text-fontColor2 bg-transparent hover:bg-primary/[0.03] border-borderColor'
                            }
                    `}>
                        {!isNull(inProgress)
                            ? <Spinner className="w-5 h-5 animate-spin" />
                            : t(renderAppoStatusName(buttonDetails?.name ?? ""))
                        }
                    </button>

                    <button
                        onClick={toggleShowDropDown}
                        className={`outline-none rounded-md p-3.5 transition-colors duration-200 textBody-s2 border text-fontColor2 bg-transparent hover:bg-primary/[0.03] border-borderColor`}>
                        <PointsMenu className="w-5 h-5" />
                    </button>
                </div>

                {showDropDown && (
                    <ModalLayout
                        wallBackground={`bg-black/15`}
                        className={`absolute ltr:right-0 rtl:left-0 bottom-0 z-[1019] w-full px-4`}
                        close={toggleShowDropDown}
                        ForgetScrollBar={true}
                    >
                        <div className="shadow-lg bg-white animation-01 animation-02 w-full h-full rounded-t-xl overflow-hidden py-4 px-4 flex flex-col lg:textBody-s3 textBody-xs3">
                            <div className="mb-4 pb-4 pt-1 border-b border-borderColor flex items-center justify-between">
                                <span className="lg:textBody-s2 textBody-xs2">{t('appo_d_2')}</span>

                                <button onClick={toggleShowDropDown} className="outline-none hover:text-primary">
                                    <Hide1 className="lg:w-5 lg:h-5 w-4 h-4" />
                                </button>
                            </div>
                            <button
                                disabled={!isNull(inProgress)}
                                onClick={() => handleChangeStatus('active')}
                                className={`text-start whitespace-nowrap py-3 px-4 rounded-md flex items-center justify-between ${renderAppoStatusColors('active', status)}`}>
                                <span className="lg:textBody-s3 textBody-xs3">{t("dash_appo_8")}</span>
                                {!isNull(inProgress) && inProgress === "active" && <Spinner className="w-4 h-4 animate-spin" />}
                            </button>
                            <button
                                disabled={!isNull(inProgress)}
                                onClick={() => handleChangeStatus('confirmed')}
                                className={`text-start whitespace-nowrap py-3 px-4 rounded-md flex items-center justify-between ${renderAppoStatusColors('confirmed', status)}`}>
                                <span className="lg:textBody-s3 textBody-xs3">{t("dash_appo_9")}</span>
                                {!isNull(inProgress) && inProgress === "confirmed" && <Spinner className="w-4 h-4 animate-spin" />}
                            </button>
                            <button
                                disabled={!isNull(inProgress)}
                                onClick={() => handleChangeStatus('canceled')}
                                className={`text-start whitespace-nowrap py-3 px-4 rounded-md flex items-center justify-between ${renderAppoStatusColors('canceled', status)}`}>
                                <span className="lg:textBody-s3 textBody-xs3">{t("dash_appo_11")}</span>
                                {!isNull(inProgress) && inProgress === "canceled" && <Spinner className="w-4 h-4 animate-spin" />}
                            </button>
                            <button
                                disabled={!isNull(inProgress)}
                                onClick={() => handleChangeStatus('completed')}
                                className={`text-start whitespace-nowrap py-3 px-4 rounded-md flex items-center justify-between ${renderAppoStatusColors('completed', status)}`}>
                                <span className="lg:textBody-s3 textBody-xs3">{t("dash_appo_10")}</span>
                                {!isNull(inProgress) && inProgress === "completed" && <Spinner className="w-4 h-4 animate-spin" />}
                            </button>
                        </div>
                    </ModalLayout>
                )}
            </div>
        </div>
    )
}

const renderAppoStatusColors = (stts, s) => {
    switch (stts) {
        case 'active':
            return `${s === "active" ? "bg-green-100 text-green-500" : "text-fontColor2/80 hover:bg-green-100 hover:text-green-500"}`;
        case 'confirmed':
            return `${s === "confirmed" ? "bg-blue-100 text-blue-500" : "text-fontColor2/80 hover:bg-blue-100 hover:text-blue-500"}`;
        case 'canceled':
            return `${s === "canceled" ? "bg-red-100 text-red-500" : "text-fontColor2/80 hover:bg-red-100 hover:text-red-500"}`;
        case 'completed':
            return `${s === "completed" ? "bg-gray-100 text-gray-500" : "text-fontColor2/80 hover:bg-gray-100 hover:text-gray-500"}`;
        default:
            return `${s === "" ? "" : "text-fontColor2/80 hover:bg-gray-100 hover:text-gray-500"}`;
    }
}