import { GetSalonByMember } from "../../lib/api";
import { useAlerts } from "../../context/AlertsContext";
import { QRCodeSVG } from 'qrcode.react';
import { Copy, PreviewIcon } from "../../icons";
import ShareBtn from "./ShareBtn";
import { useTranslation } from "react-i18next";
import getEnv from "../../Helpers/env";



export default function Share() {

    const { t } = useTranslation()

    const { addAlert } = useAlerts();

    const { data, loading } = GetSalonByMember();


    const salonUrl = getEnv('appFrontendUrl') + data?.data?.slug ?? '#';



    const handleCopyLink = () => {
        navigator?.clipboard?.writeText(salonUrl)
        addAlert(t('dash_share_2'), 'success')
    }

    const handlePreviewBtn = () => {
        window?.open(salonUrl, '_blank');
    }

    return (
        <div className="xl:p-8 lg:p-6 sm:p-4 p-4 mt-6">
            <div className="w-full max-w-5xl mx-auto text-center">
                <div className="mb-3 pb-3">
                    <h2 className="textHeader-s">{t("dash_share_1")}</h2>
                </div>

                {loading ? suspense() :
                    <>
                        <div className="bg-white w-fit p-2 border border-borderColor hover:border-primary/20 rounded-lg mx-auto mt-4 mb-6">
                            <QRCodeSVG
                                value={salonUrl}
                                size={200}
                            />
                        </div>
                        <div className="max-w-md mx-auto flex flex-col gap-2">
                            <div className="w-full mx-auto flex gap-2">
                                <div
                                    onClick={handleCopyLink}
                                    className="bg-white select-none w-full max-w-full overflow-x-auto no-scrollbar py-2 px-3 border border-borderColor hover:border-primary/20 rounded-lg cursor-context-menu ltr:text-left rtl:text-right">
                                    {salonUrl}
                                </div>

                                <button
                                    onClick={handleCopyLink}
                                    className="bg-white outline-none w-fit py-2 px-3 border border-borderColor hover:border-primary/20 rounded-lg"
                                >
                                    <Copy className="w-4 h-4" />
                                </button>
                                <button
                                    onClick={handlePreviewBtn}
                                    className="bg-white outline-none w-fit py-2 px-3 border border-borderColor hover:border-primary/20 rounded-lg"
                                >
                                    <PreviewIcon className="w-4 h-4" />
                                </button>
                            </div>

                            <ShareBtn
                                link={salonUrl}
                                handleCopyLink={handleCopyLink}
                            />
                        </div>
                    </>}
            </div>
        </div>
    )
}

const suspense = () => {
    return (
        <div className="animate-pulse">
            <div className="bg-white p-2 border border-borderColor rounded-lg mx-auto mt-4 mb-6 size-[200px]"></div>
            <div className="max-w-md mx-auto flex flex-col gap-2">
                <div className="w-full mx-auto flex gap-2">
                    <div className="bg-white w-full size-11 py-2 px-3 border border-borderColor rounded-lg"></div>
                    <div className="bg-white size-11 py-2 px-3 border border-borderColor rounded-lg"></div>
                    <div className="bg-white size-11 py-2 px-3 border border-borderColor rounded-lg"></div>
                </div>

                <div className="bg-white w-full size-11 py-2 px-3 border border-borderColor rounded-lg"></div>
            </div>
        </div>
    )
}