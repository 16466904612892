import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardLayout from './layouts/DashboardLayout';
import NotFound from './components/NotFound';
import ScrollToTop from './components/ScrollToTop';
import FixSiteDNS from './components/FixSiteDNS';
import { AlertsProvider } from './context/AlertsContext';
import AlertsList from './components/Alerts/AlertsList';
import Dashboard from './pages/Dashboard';
import Appointments from './pages/Appointments';
import Calendar from './pages/Calendar';
import Salon from './pages/Salon';
import Account from './pages/Account';
import Share from './pages/Share';
import NewTicket from './pages/NewTicket';
import Worktime from './pages/Worktime';
import Settings from './pages/Settings';
import Services from './pages/Services';
import Clients from './pages/Clients';



export default function App() {
    return (
        <AlertsProvider>
            <AlertsList />
            <BrowserRouter>
                <ScrollToTop />
                <FixSiteDNS />

                <Routes>
                    <Route path="/" element={<DashboardLayout />}>
                        <Route index element={<Dashboard />} />

                        <Route path='calendar' element={<Calendar />} />
                        <Route path='appointments' element={<Appointments />} />
                        <Route path='account' element={<Account />} />
                        <Route path='salon' element={<Salon />} />
                        <Route path='share' element={<Share />} />
                        <Route path='clients' element={<Clients />} />

                        <Route path='worktime' element={<Worktime />} />
                        <Route path='settings' element={<Settings />} />

                        <Route path='services' element={<Services />} />

                        <Route path='new-ticket' element={<NewTicket />} />
                    </Route>

                    <Route path='*' element={<NotFound />}></Route>
                </Routes>
            </BrowserRouter>
        </AlertsProvider>
    );
}
