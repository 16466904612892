import SalonCard from "./SalonCard";
import { GetSalonByMember } from "../../lib/api";
import { useAlerts } from "../../context/AlertsContext";
import { Link } from "react-router-dom";
import { n } from "../../Helpers/utils";
import { useTranslation } from "react-i18next";



const Salon = () => {

    const { t } = useTranslation()

    const { addAlert } = useAlerts();

    const { data, loading, refresh } = GetSalonByMember();

    return (
        <div className="xl:p-8 lg:p-6 p-4">
            <div className="w-full max-w-5xl mx-auto">
                <div className="mb-3 max-lg:mt-3 pb-3 flex items-center justify-between">
                    <h2 className="textHeader-s">{t("dash_salon_1")}</h2>

                    {n(data?.data?.is_visible) === 1 &&
                        <Link to="/share">
                            <button className="textBody-s2 text-secondary hover:underline flex gap-1">
                                <span className="">{t("dash_salon_2")}</span>
                                <span className="lg:block hidden">{t("dash_salon_3")}</span>
                            </button>
                        </Link>
                    }
                </div>

                <SalonCard
                    loading={loading}
                    salonData={data?.data}
                    refresh={refresh}
                    addAlert={addAlert}
                />
            </div>
        </div>
    )
}

export default Salon;