import { CheckIcon } from "../../icons";

export default function CheckBox2({
    isChecked = false,
    onChange = () => { },
}) {
    return (
        <div
            onClick={onChange}
            className={`lg:w-[20px] lg:h-[20px] w-[18px] h-[18px] rounded-md p-0.5 flex items-center cursor-pointer hover:opacity-95 shadow-sm border ${isChecked ? 'bg-secondary border-secobg-secondary' : 'border-[#d3d3d6] bg-white'}`}>
            {isChecked && <CheckIcon className="w-5 h-5 text-white" />}
        </div>
    )
}