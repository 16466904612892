import { useState } from "react";
import axiosClient from "../lib/axiosClient"
import useSWR from "swr";
import useCookies from "./useCookies";
import { hash } from "./useHashRouter";
import getEnv from "../Helpers/env";


export const useAuth = () => {


    const { removeGlobalCookie, getCookie } = useCookies()


    // check if found cookie "_uln" or not
    const isAuth = getCookie("_uln") || null;


    // State to store 'isLoading' value, for all requests in this page
    const [isLoading, setIsLoading] = useState(false)


    // get frontend url
    const frontEndUrl = getEnv('appFrontendUrl') || "/";


    // get & set sanctum tokens
    const csrf = () => axiosClient.get('sanctum/csrf-cookie');


    /**
     * Get auth user data
     */
    const authUser = useSWR(isAuth ? hash("auth/user") : null, () =>
        axiosClient.get(isAuth ? hash("auth/user") : null)
            .then(async res => {
                const userData = res.data;

                return userData;
            })
            .catch(async error => {
                if (error.response.data.message === 'Unauthenticated.') {
                    removeGlobalCookie("_uln");
                    removeGlobalCookie("sharely_cookie"); // remove also this cookie (just now, because login strictur updated)

                    window.location.href = frontEndUrl;
                }
            })
    );


    /**
     * Logout
     */
    const logout = async () => {
        try {
            setIsLoading(true)

            await csrf();

            const { data } = await axiosClient.post(hash('auth/logout'));

            if (data.is_ok === true) {
                removeGlobalCookie("_uln");
                removeGlobalCookie("sharely_cookie"); // remove also this cookie (just now, because login strictur updated)

                window.location.href = frontEndUrl;
            }

        } catch (error) {
            if (error.response.data.message === 'Unauthenticated.') {
                removeGlobalCookie("_uln");
                removeGlobalCookie("sharely_cookie"); // remove also this cookie (just now, because login strictur updated)
            }

        } finally {
            setIsLoading(false)
        }
    }


    return {
        isAuth,
        authUser,
        csrf,
        isLoading,
        logout
    };
}