import { Link, useLocation } from "react-router-dom";
import { Appointments, Dashboard, Menu, Salon, Share } from "../../icons";
import { useTranslation } from "react-i18next";



export default function SideBarMobile() {

    const { t } = useTranslation()

    // Get path name
    const location = useLocation();
    const { pathname } = location;


    const asideLinks = [
        {
            label: t('mobile_side_bar_1'),
            icon: <Dashboard className="w-5 h-5" />,
            path: '/',
        }, {
            label: t('mobile_side_bar_2'),
            icon: <Appointments className="w-5 h-5" />,
            path: '/appointments',
        }, {
            label: t('mobile_side_bar_3'),
            icon: <Salon className="w-5 h-5" />,
            path: '/salon',
        }, {
            label: t('mobile_side_bar_4'),
            icon: <Share className="w-5 h-5" />,
            path: '/share',
        }, {
            label: t('mobile_side_bar_5'),
            icon: <Menu className="w-5 h-5" />,
            path: '/settings',
        },
    ];


    const isActive = (path) => {
        return path === pathname;
    }

    return (
        <div className="sticky bottom-0 lg:hidden h-[60px]">
            <aside className={`shrink-0 h-full text-white flex items-center md:gap-2 justify-around textBody-s3 bg-white scrollBar1 transition-all duration-300 z-[1001] w-full overflow-y-hidden overflow-x-auto`}>
                {asideLinks.map((link, index) => {
                    const { path, icon, label } = link ?? {};
                    const act = isActive(path);
                    return (
                        <Link to={`${link.path ?? '/'}`} key={index} className="w-full flex flex-col">
                            <div className={`px-2 py-2.5 flex flex-col items-center gap-1.5 transition-colors duration-200 cursor-pointer border-t ${act ? 'bg-primary/[0.03] text-primary border-primary' : 'text-fontColor2 border-borderColor'}`}>
                                {icon}
                                <span className={`textBody-2xs3 whitespace-nowrap ${act ? 'text-fontColor' : 'text-fontColor2'}`}>{label}</span>
                            </div>
                        </Link>
                    )
                })}
            </aside>
        </div>
    )
}