import { useTranslation } from "react-i18next";
import SelectServices from "./SelectServices";
import ServicesList from "./ServicesList";
import { useEffect, useState } from "react";
import { isNull } from "../../Helpers/utils";
import UpdateService from "./UpdateService";
import { GetServicesBySalon, UpdateServices } from "../../lib/api";
import { Spinner } from "../../icons";
import { useAlerts } from "../../context/AlertsContext";


export default function Services() {

    const { t } = useTranslation()

    const { addAlert } = useAlerts()


    // Get salon's worktime
    const { data, loading } = GetServicesBySalon({});


    const [services, setServices] = useState([])


    useEffect(() => {
        setServices(data?.data ?? []);
    }, [data]);


    // state to store id of editing service
    const [selectedServiceToEdit, setSelectedServiceToEdit] = useState({})


    const [inProgress, setInProgress] = useState(false)
    const saveUpdates = async (d) => {
        setInProgress(true)

        const { is_ok, message, data: n_data } = await UpdateServices({ data: d });

        setInProgress(false)

        addAlert(message ?? (is_ok ? t('mssg_1') : t('mssg_2')), is_ok ? 'success' : 'error');

        if (is_ok) {
            setServices(n_data)
        }
    }

    
    // remove service
    const handleRemoveService = (c_id, s_id) => {
        if (isNull(c_id) || isNull(s_id)) return;

        const updatedData = services?.map((category) => {
            if (category.id !== c_id) return category;

            const updatedServices = category.services?.filter((service) => service.id !== s_id);

            return updatedServices?.length > 0 ? { ...category, services: updatedServices } : null;
        }).filter(Boolean);

        // setServices(updatedData)
        saveUpdates(updatedData)
    }


    // update service's duration, price
    const handleUpdateService = (c_id, s_id, duration, price) => {
        if (isNull(c_id) || isNull(s_id) || isNull(duration) || isNull(price)) return;

        const updatedData = services?.map((category) => {
            if (category.id !== c_id) return category;

            const updatedServices = category.services?.map((service) => {
                if (service.id !== s_id) return service;

                // Update the service's duration and price
                return {
                    ...service,
                    duration,
                    price,
                };
            });

            return {
                ...category,
                services: updatedServices,
            };
        });

        // setServices(updatedData)
        saveUpdates(updatedData)
    }


    // check services is selected or not by id
    const isServiceSelected = (s_id) => {
        if (isNull(s_id)) return false;

        return services?.some((category) =>
            category.services?.some((service) => service.id === s_id)
        );
    }


    // handle select service
    const handleSelectService = (c_id, s_id, price, duration, currency) => {
        if (isNull(c_id) || isNull(s_id) || isNull(price) || isNull(duration) || isNull(currency)) return;


        const newService = { id: s_id, price, duration, currency };


        const updateData = (prevServices) => {
            if (isNull(prevServices)) return [{ id: c_id, services: [newService] }];
    
            const updatedData = [...prevServices];
            const category = updatedData.find((cat) => cat.id === c_id);
    
            if (category) {
                // Category exists: Add the new service
                category.services = category.services || [];
                category.services.push(newService);
            } else {
                // Category does not exist: Add a new category with the service
                updatedData.push({ id: c_id, services: [newService] });
            }
    
            return updatedData;
        };
        
        const updatedData = updateData(services);

        saveUpdates(updatedData)
    }


    // Check if services equal 'n' number
    const countServices = (n) => {
        if (isNull(n) || isNull(services)) return false; // Ensure valid inputs

        // Calculate the total number of services
        const totalServices = services?.reduce((count, category) => {
            return count + (category.services?.length || 0);
        }, 0);

        // Return whether the total matches the specified number
        return totalServices === n;
    };


    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 p-4">
            <div className="mb-3 max-lg:mt-3 pb-3 flex items-center justify-between">
                <div className="flex flex-col gap-1">
                    <h2 className="flex items-center textHeader-l">{t('services_05')}</h2>
                    <p className="textBody-s3">{t('services_06')}</p>
                </div>

                <div className="flex items-center gap-4">
                    {inProgress && <Spinner className="w-6 h-6 animate-spin text-primary max-lg:fixed max-lg:bottom-[75px] max-lg:ltr:right-6 max-lg:rtl:left-6" />}
                    {!loading && !countServices(18) &&
                    <SelectServices
                        t={t}
                        isDisabled={inProgress || loading}
                        isServiceSelected={isServiceSelected}
                        handleSelectService={handleSelectService}
                        inProgress={inProgress}
                    />}
                </div>
            </div>


            <ServicesList
                data={services}
                t={t}
                setSelectedServiceToEdit={setSelectedServiceToEdit}
                handleRemoveService={handleRemoveService}
                loading={loading}
                isDisabled={inProgress}
            />


            {/* Edit */}
            {!isNull(selectedServiceToEdit) &&
                <UpdateService
                    service={selectedServiceToEdit}
                    close={() => setSelectedServiceToEdit({})}
                    handleUpdateService={handleUpdateService}
                    t={t}
                />
            }
        </div>
    )
}