import { useEffect, useState } from "react";
import { isNull, n, placeholderImage, renderImage } from "../../Helpers/utils";
import FormModal from "../../components/Shared/FormModal";
import Avatar from "../../components/Shared/Avatar";
import Input from "../../components/Shared/Input";
import { Edit1 } from "../../icons";
import { UpdateSalonBaseData } from "../../lib/api";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";


export default function SalonCard({ loading, salonData, refresh, addAlert }) {

    const { t } = useTranslation()

    const { id, name, is_visible, images, total_appointments } = salonData ?? {};

    const [showForm, setShowForm] = useState(false)
    const toggleShowForm = () => setShowForm(!showForm);


    return loading ? suspend() : (
        <>
            <div onClick={toggleShowForm} className="bg-white rounded-md border border-borderColor/50 hover:border-primary cursor-pointer transition-colors duration-200">
                <div className="w-full flex max-md:flex-col">
                    <div className="p-0 md:p-4 md:pr-2">
                        <div className="lg:w-32 w-full h-auto bg-primary/5 rounded-md overflow-hidden border">
                            {images
                                ? <img src={renderImage(images)} alt="salon" className="w-full" />
                                : <img src={placeholderImage} alt="salon" className="w-full" />
                            }
                        </div>
                    </div>

                    <div className="w-full flex gap-4 justify-between pl-2 p-4">
                        <div className="flex flex-col gap-1">
                            <h3 className="textBody-m1">{name}</h3>
                            <p className="textBody-s3">{`(${total_appointments ?? 0}) ${t("dash_salon_4")}`}</p>
                        </div>

                        <div className="">
                            <span className={`textBody-xs2 px-2.5 py-1 rounded border outline-none ${n(is_visible) === 1 ? 'text-green-500 border-green-200 hover:border-green-300 bg-green-100' : 'text-gray-500 border-gray-200 hover:border-gray-300 bg-gray-100'}`}>
                                {n(is_visible) === 1 ? t("dash_salon_5") : t("dash_salon_6")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {showForm && (
                <UpdateData
                    close={toggleShowForm}
                    setNotif={addAlert}
                    salonInfo={{
                        image: images,
                        name: name,
                        is_visible: is_visible,
                        id: id,
                    }}
                    refresh={refresh}
                />
            )}
        </>
    )
}


const suspend = () => {
    return (
        <div className="bg-white rounded-md border border-borderColor p-4 lg:p-6 animate-pulse overflow-hidden">
            <div className="w-full flex max-lg:flex-col gap-4">
                <div className="">
                    <div className="lg:w-32 lg:h-32 w-full h-40 bg-primary/5 rounded-md overflow-hidden">
                    </div>
                </div>

                <div className="w-full flex gap-4 justify-between">
                    <div className="flex flex-col gap-1">
                        <div className="h-6 md:w-40 w-28 rounded-md text-gray-500 border-gray-200 bg-gray-100"></div>
                        <div className="h-5 w-20 rounded-md text-gray-500 border-gray-200 bg-gray-100"></div>
                    </div>

                    <div className="">
                        <div className="h-6 md:w-40 w-28 rounded-md text-gray-500 border-gray-200 bg-gray-100"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}


// Edit modal
const UpdateData = ({ close, setNotif, salonInfo, refresh }) => {


    // ########################################################
    const [searchParams] = useSearchParams();
    const queryName = "new";

    const getActionQuery = () => searchParams.get(queryName) ?? '';

    const isNew = getActionQuery() === "1";
    // ########################################################


    const navigate = useNavigate()

    const { t } = useTranslation()

    const [image, setImage] = useState("")
    const [imagePreview, setImagePreview] = useState("")
    const [name, setName] = useState("")
    const [is_visible, setIs_visible] = useState("")
    const [errors, setErrors] = useState([])
    const [inProgress, setInProgress] = useState(false)


    useEffect(() => {
        setImage("")
        setImagePreview(renderImage(salonInfo.image) ?? placeholderImage)
        setName(salonInfo.name)
        setIs_visible(salonInfo?.is_visible)
    }, [salonInfo])


    // handle change user image
    const handleChangeImage = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result); // Base64 string
                setImagePreview(reader.result); // Base64 string
            };
            reader.readAsDataURL(file);
        } else {
            setImage('')
            setImagePreview(renderImage(salonInfo.image) ?? placeholderImage);
        }
    }


    // send request
    const saveChanges = async () => {

        setErrors({})
        setInProgress(true)

        const data = {
            salon_id: salonInfo.id,
            image, // Base64 string
            name,
            is_visible: n(is_visible) === 1 ? true : false,
        };

        const { is_ok, message, type, errors } = await UpdateSalonBaseData(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            setNotif(message ?? (is_ok ? 'Success!' : 'Something was wrong!'), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            refresh();
            close();

            if (isNew) {
                navigate('/share')
            }
        }
    }

    return (
        <FormModal
            close={close}
            title={t('dash_salon_7')}
            save={saveChanges}
            isDisabled={inProgress}
            isLoading={inProgress}
        >
            <div className=" mb-2 flex">
                <label className="relative w-fit border border-primaryBorder/60 rounded-xl">
                    <Avatar
                        src={imagePreview}
                        borderRadius="rounded-xl"
                        size={'w-24 h-24'}
                        alt={salonInfo?.name}
                    />

                    <div className="absolute bottom-0 ltr:right-0 rtl:left-0 bg-secondary text-white rounded-lg p-1.5 cursor-pointer shadow-md">
                        <input type="file" onChange={handleChangeImage} accept="image/png, image/jpg, image/jpeg" hidden />
                        <Edit1 className="w-4 h-4" />
                    </div>
                </label>
            </div>
            <Input
                type={"text"}
                title={t('dash_salon_8')}
                placeholder={"..."}
                value={name}
                change={(e) => setName(e.target.value)}
                error={errors.name ?? ''}
            />
            <label className="flex flex-col gap-2">
                <span className="textBody-s1">{t("dash_salon_9")}</span>
                <select
                    value={is_visible}
                    onChange={(e) => setIs_visible(e.target.value)}
                    className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                    <option value={1}>{t("dash_salon_5")}</option>
                    <option value={0}>{t("dash_salon_6")}</option>
                </select>
                {!isNull(errors.is_visible) && <p className="textBody-s2 text-red-600">{errors.is_visible}</p>}
            </label>
        </FormModal>
    )
}