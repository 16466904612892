import Pagination from "../../components/Pagination"
import Avatar from "../../components/Shared/Avatar"
import { getFullName, isNull, renderImage } from "../../Helpers/utils"





export default function ClientsList({ loading, clients, current_page, last_page, setPageNumber, t }) {
    return loading ? suspense() :
        isNull(clients) ? noResults(t("cl_08")) : (
            <>
                <div className="overflow-y-hidden rounded-md -mx-1">
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead className="text-fontColor border-b border-borderColor/60">
                                <tr className="[&>*]:px-1 [&>*]:py-2">
                                    <th className=" textBody-s1 ltr:text-left rtl:text-right">{t("cl_05")}</th>
                                    <th className=" textBody-s1 ltr:text-left rtl:text-right max-lg:hidden">{t("cl_06")}</th>
                                    <th className=" textBody-s1 ltr:text-right rtl:text-left">{t("cl_07")}</th>
                                </tr>
                            </thead>
                            <tbody className="text-fontColor">

                                {clients?.map((c) => {
                                    return (
                                        <tr key={c?.username} className="[&>*]:px-1 [&>*]:py-5 border-b border-borderColor/60">
                                            <td className="">
                                                <div className="flex items-center gap-2 select-none">
                                                    <div>
                                                        <Avatar
                                                            src={renderImage(c?.avatar)}
                                                            size="lg:w-11 lg:h-11 w-8 h-8"
                                                            alt={getFullName(c)}
                                                            className="rounded-full border-2 border-borderColor shadow-sm"
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <p className="lg:textBody-m2 textBody-s2 line-clamp-1">{getFullName(c)}</p>
                                                        <a
                                                            href={`https://wa.me/${c?.phone ?? ""}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="lg:hidden whitespace-nowrap hover:underline hover:text-primary textBody-xs3">
                                                            {c?.phone?.replace('+212', '')}
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="textBody-s3 max-lg:hidden">
                                                <a
                                                    href={`https://wa.me/${c?.phone ?? ""}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="whitespace-nowrap hover:underline hover:text-primary">
                                                    {c?.phone?.replace('+212', '')}
                                                </a>
                                            </td>

                                            <td className="ltr:text-right rtl:text-left">
                                                <span className="textBody-s3">{c?.total_appointments}</span>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                <Pagination
                    className="mb-20 py-2"
                    currentPageNumber={current_page}
                    lastPageNumber={last_page}
                    onChange={(n) => setPageNumber(n)}
                    disabled={loading}
                />
            </>
        )
}

const suspense = () => {
    return (
        <>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((n, index) => (
                <div key={index} className="py-5 flex flex-col bg-white border-b border-borderColor/50 rounded-lg cursor-context-menu select-none animate-pulse overflow-hidden">
                    <div className="w-full flex justify-between items-center">
                        <div className="flex items-center gap-2">
                            <div>
                                <div className="lg:w-11 lg:h-11 w-8 h-8 rounded-full bg-[#efefef]/80"></div>
                            </div>
                            <div className="flex flex-col gap-0.5">
                                <div className="bg-[#efefef]/80 h-5 md:w-28 w-20 rounded-xl"></div>
                                <div className="bg-[#efefef]/80 h-4 md:w-28 w-20 rounded-xl lg:hidden"></div>
                            </div>
                        </div>

                        <div className="bg-[#efefef]/80 h-4 md:w-28 w-20 rounded-xl max-lg:hidden"></div>

                        <div className="flex gap-1">
                            <div className="w-8 h-8 rounded-md bg-[#efefef]/80"></div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

const noResults = (txt) => {
    return (
        <div className="bg-white grid gap-4 w-full border border-borderColor/50 rounded-lg py-6 px-4">
            <div className="w-20 h-20 mx-auto bg-gray-50 rounded-full shadow-sm justify-center items-center inline-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                    <g id="File Serch">
                        <path id="Vector" d="M19.9762 4V8C19.9762 8.61954 19.9762 8.92931 20.0274 9.18691C20.2379 10.2447 21.0648 11.0717 22.1226 11.2821C22.3802 11.3333 22.69 11.3333 23.3095 11.3333H27.3095M18.6429 19.3333L20.6429 21.3333M19.3095 28H13.9762C10.205 28 8.31934 28 7.14777 26.8284C5.9762 25.6569 5.9762 23.7712 5.9762 20V12C5.9762 8.22876 5.9762 6.34315 7.14777 5.17157C8.31934 4 10.205 4 13.9762 4H19.5812C20.7604 4 21.35 4 21.8711 4.23403C22.3922 4.46805 22.7839 4.90872 23.5674 5.79006L25.9624 8.48446C26.6284 9.23371 26.9614 9.60833 27.1355 10.0662C27.3095 10.524 27.3095 11.0253 27.3095 12.0277V20C27.3095 23.7712 27.3095 25.6569 26.138 26.8284C24.9664 28 23.0808 28 19.3095 28ZM19.3095 16.6667C19.3095 18.5076 17.8171 20 15.9762 20C14.1352 20 12.6429 18.5076 12.6429 16.6667C12.6429 14.8257 14.1352 13.3333 15.9762 13.3333C17.8171 13.3333 19.3095 14.8257 19.3095 16.6667Z" stroke="#4F46E5" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                </svg>
            </div>
            <div>
                <h2 className="text-center textBody-s2 pb-1 text-fontColor2">{txt}</h2>
            </div>
        </div>
    )
}