import { useState } from "react"
import { Hide1 } from "../../icons"
import { isNull } from "../../Helpers/utils"




export default function Header({ total, handleSearchFilter, t }) {

    const [val, setVal] = useState("")

    const handleOnChange = (val) => {
        setVal(val)
        handleSearchFilter(val)
    }


    return (
        <div className="my-4 w-full bg-gradient-to-tr from-primary/[0.06] to-primary/[0.02] rounded-lg lg:p-4 p-2">
            <div className="w-full flex justify-between items-center gap-2">
                <div className="w-full flex items-center gap-2">
                    <label
                        className="border px-4 rounded-full bg-white flex items-center gap-2 w-full lg:max-w-[70%] max-w-[90%] transition-all duration-200 ease-in border-borderColor/80"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="w-4 h-4"
                        >
                            <path
                                d="M17.5 17.5L22 22"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <input
                            placeholder={t("cl_03")}
                            type="text"
                            className="outline-none bg-transparent w-full py-2 lg:px-4 px-2 textBody-s3"
                            value={val}
                            onChange={(e) => handleOnChange(e.target.value)}
                        />
                        {!isNull(val) ? <span onClick={() => handleOnChange("")}><Hide1 className="w-4 h-4 hover:scale-110" /></span> : null}
                    </label>
                </div>
                <span className="whitespace-nowrap textBody-s1">{t("cl_04", { total: total ?? 0 })}</span>
            </div>
        </div>
    )
}