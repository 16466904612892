import { useEffect, useState } from "react";
import CheckBox2 from "./CheckBox2";
import TimeSelector from "./TimeSelector";
import { isNull } from "../../Helpers/utils";
import dayjs from "../../lib/dayjs";
import { GetWorkTimeBySalon, UpdateWorkTime } from "../../lib/api";
import { Spinner } from "../../icons";
import { useAlerts } from "../../context/AlertsContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


export default function Worktime() {

    const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];


    const { t } = useTranslation()


    const navigate = useNavigate()


    const { addAlert } = useAlerts()


    // Get salon's worktime
    const { data, loading, refresh } = GetWorkTimeBySalon({});


    const [selectedDays, setSelectedDays] = useState([]);


    useEffect(() => {
        if (data?.data?.length > 0) {
            setSelectedDays(data?.data);
        }
    }, [data]);


    const toggleSelectDay = (day) => {
        setSelectedDays((prevSelectedDays) => {
            const isSelected = prevSelectedDays.some((item) => item.day === day);

            if (isSelected) {
                // Remove the day if it's already selected
                return prevSelectedDays.filter((item) => item.day !== day);
            } else {
                // get day if already selected or add new
                const current_day = data?.data?.find(i => i.day === day) || {};
                const new_day = { day: day, start: '08:00 AM', end: '23:00 PM' };

                return [...prevSelectedDays, !isNull(current_day) ? current_day : new_day];
            }
        });
    };


    // handle select/change start/end of time
    const handleChangeTime = (day, type, value) => {
        setSelectedDays((prevSelectedDays) => {
            return prevSelectedDays.map((item) => {
                if (item.day === day) {
                    // Update the `from` or `to` field based on the type
                    return { ...item, [type]: value };
                }
                return item; // No change for other days
            });
        });
    }


    const [inProgress, setInProgress] = useState(false)
    const handleSaveChangesBtn = async () => {
        setInProgress(true)

        const { is_ok, message } = await UpdateWorkTime({ data: selectedDays });

        setInProgress(false)

        addAlert(message ?? (is_ok ? t('mssg_1') : t('mssg_2')), is_ok ? 'success' : 'error');

        if (is_ok) {
            refresh();
        }
    }


    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 p-4">
            <div className="mb-3 max-lg:mt-3 pb-3 flex flex-col gap-1 items-center text-center">
                <h2 className="textHeader-s">{t('worktime_1')}</h2>
                <p className="lg:textBody-s3 textBody-xs3 text-fontColor2">{t('worktime_2')}</p>
            </div>
            <div className="max-w-2xl w-full mx-auto bg-white border border-borderColor shadow-sm rounded-lg">
                {loading ? suspend() :
                    <div className="flex flex-col gap-2 py-4">

                        {daysOfWeek?.map((day, index) => {

                            const getDayData = selectedDays.find(i => i.day === day);
                            const isSelected = !isNull(getDayData);

                            const startTimeValue = isSelected ? getDayData['start'] ?? "" : "";
                            const EndtTimeValue = isSelected ? getDayData['end'] ?? "" : "";

                            const toDate = dayjs().format('YYYY-MM-DD');
                            const startTimeValueInDate = dayjs(`${toDate} ${startTimeValue.replace(' PM', '').replace(' AM', '')}`);
                            const EndtTimeValueInDate = dayjs(`${toDate} ${EndtTimeValue.replace(' PM', '').replace(' AM', '')}`);
                            const totalHours = EndtTimeValueInDate.diff(startTimeValueInDate, 'hour', true);

                            return (
                                <div key={index} className="px-4 w-full flex max-lg:flex-col lg:items-center items-center justify-between lg:gap-3 gap-1.5 py-2 hover:bg-secondary/[0.03] transition duration-200">
                                    <div onClick={() => toggleSelectDay(day)} className="flex items-center max-lg:flex-row-reverse max-lg:justify-between max-lg:w-full gap-2.5">
                                        <CheckBox2
                                            isChecked={isSelected}
                                        />
                                        <div className="cursor-pointer select-none">
                                            <h3 className="inline-block lg:textBody-m1 textBody-s1">{t(day)}</h3>
                                            <span className="inline-block lg:textBody-xs3 textBody-2xs3 text-fontColor2 ltr:ml-1 rtl:mr-1">{`${totalHours} ${t('worktime_5')}`}</span>
                                        </div>
                                    </div>

                                    <div className="max-lg:w-full grid grid-cols-1 gap-2">
                                        <div className="flex items-center lg:gap-2 gap-1">
                                            <TimeSelector
                                                type="start"
                                                isDisabled={!isSelected}
                                                value={startTimeValue}
                                                change={(v) => handleChangeTime(day, 'start', v)}
                                                times={{ start: startTimeValue, end: EndtTimeValue }}
                                            />
                                            <span className="cursor-context-menu text-fontColor2">-</span>
                                            <TimeSelector
                                                type="end"
                                                isDisabled={!isSelected}
                                                value={EndtTimeValue}
                                                change={(v) => handleChangeTime(day, 'end', v)}
                                                times={{ start: startTimeValue, end: EndtTimeValue }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                }
            </div>

            {/* Buttons */}
            {!loading &&
                <div className="w-full max-w-2xl mx-auto py-6 mb-16">
                    <div className="flex items-center justify-end lg:gap-4 gap-2">
                        <button
                            disabled={inProgress || loading}
                            onClick={() => navigate(-1)}
                            className="outline-none whitespace-nowrap rounded-md py-3 lg:px-6 px-4 select-none textBody-s2 bg-transparent border border-borderColor text-dangerColor hover:bg-primary/[0.03] transition-colors duration-200">
                            {t('worktime_3')}
                        </button>
                        <button
                            disabled={inProgress || loading}
                            onClick={handleSaveChangesBtn}
                            className="outline-none whitespace-nowrap rounded-md py-3 lg:px-6 px-4 transition-colors duration-200 select-none textBody-s2 bg-secondary hover:bg-secondary/90 border border-secondary text-white">
                            {!inProgress ? t('worktime_4') : <Spinner className="w-4 h-4 animate-spin" />}
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}


const suspend = () => {
    return (
        <div className="flex flex-col gap-2 py-4">
            {[1, 2, 3, 4, 5, 6, 7].map((n, index) => (
                <div key={index} className="px-4 w-full flex max-lg:flex-col items-center justify-between lg:gap-3 gap-1.5 py-2 animate-pulse">
                    <div className="flex items-center max-lg:flex-row-reverse max-lg:justify-between max-lg:w-full gap-2.5">
                        <div className="lg:w-[20px] lg:h-[20px] w-[18px] h-[18px] bg-primary/5 rounded-md"></div>
                        <div className="bg-primary/5 h-6 w-28 rounded-xl"></div>
                    </div>

                    <div className="lg:w-48 w-full grid grid-cols-2 gap-2">
                        <div className="bg-primary/5 h-[34px] rounded-md"></div>
                        <div className="bg-primary/5 h-[34px] rounded-md"></div>
                    </div>
                </div>
            ))}
        </div>
    )
}