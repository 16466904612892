import Avatar from "../../../components/Shared/Avatar";
import { getFullName, renderImage } from "../../../Helpers/utils";
import { Hide1, Whatsapp } from "../../../icons";


export default function Header({ close, client }) {

    const { avatar, phone } = client;

    return (
        <div className="bg-white lg:h-[70px] h-[60px] flex items-center justify-between px-4 lg:px-6 border-b border-borderColor max-lg:rounded-t-xl">
            <a href={`https://wa.me/${phone ?? ""}`} target="_blank" rel="noreferrer" className="group">
                <div className="flex items-center gap-2 select-none cursor-pointer">
                    <div>
                        <div className="relative">
                            <Avatar
                                src={avatar ? renderImage(avatar) : ''}
                                size="lg:w-11 lg:h-11 w-8 h-8"
                                alt={getFullName(client)}
                                className="rounded-full border-2 border-borderColor shadow-sm"
                            />
                            <Whatsapp className="lg:w-4 lg:h-4 w-3 h-3 group-hover:scale-110 bg-transparent absolute bottom-0 ltr:right-0 rtl:left-0 text-green-400 transition duration-200" />
                        </div>
                    </div>
                    <div className="">
                        <span className="lg:textBody-s2 textBody-xs2 text-fontColor line-clamp-1">{getFullName(client).charAt(0).toUpperCase() + getFullName(client).slice(1)}</span>
                        <p className="lg:textBody-xs3 textBody-2xs3 text-fontColor2">{phone?.replace('+212', '')}</p>
                    </div>
                </div>
            </a>

            {/* btn */}
            <button onClick={close} className="outline-none hover:text-primary hover:scale-110">
                <Hide1 className="w-5 h-5" />
            </button>
        </div>
    )
}