
export default function TimeSelector({ type, isDisabled, value, change, times }) {
    const TIMES = [
        "00:00 AM", "01:00 AM", "02:00 AM", "03:00 AM", "04:00 AM", "05:00 AM",
        "06:00 AM", "07:00 AM", "08:00 AM", "09:00 AM", "10:00 AM", "11:00 AM",
        "12:00 PM", "13:00 PM", "14:00 PM", "15:00 PM", "16:00 PM", "17:00 PM",
        "18:00 PM", "19:00 PM", "20:00 PM", "21:00 PM", "22:00 PM", "23:00 PM",
    ];

    const startTimeIndex = times.start
        ? TIMES.findIndex((time) => time === times.start)
        : null;

    const endTimeIndex = times.end
        ? TIMES.findIndex((time) => time === times.end)
        : null;


    return (
        <select
            disabled={isDisabled}
            value={value}
            onChange={(e) => change(e.target.value)}
            className="lg:w-fit w-full text-center border border-borderColor outline-none focus:border-secondary rounded-md md:px-4 px-2 py-2 cursor-pointer textBody-xs3 text-fontColor"
        >
            {TIMES.map((t, i) => {

                let isUnSelectable = false;

                if (type === "start" && endTimeIndex) {
                    isUnSelectable = i >= endTimeIndex;
                }
                if (type === "end" && startTimeIndex) {
                    isUnSelectable = i <= startTimeIndex;
                }

                return (
                    <option key={i} value={t} disabled={isUnSelectable}>
                        {t}
                    </option>
                );
            })}
        </select>
    );
}
