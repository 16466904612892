import { useState } from "react";
import { useAlerts } from "../../context/AlertsContext";
import { Spinner } from "../../icons";
import { isNull } from "../../Helpers/utils";
import SuccessMessage from "./SuccessMessage";
import SelectCategory from "./SelectCategory";
import { NewTicket } from "../../lib/api";
import { useTranslation } from "react-i18next";



export default function NewTicketComponent() {


    const { t } = useTranslation()


    const ticketsCategories = [
        {
            slug: "bug",
            label: t('ticket_5'),
        }, {
            slug: "feature",
            label: t('ticket_6'),
        }, {
            slug: "feedback",
            label: t('ticket_7'),
        }, {
            slug: "help",
            label: t('ticket_8'),
        },
    ];


    const { addAlert } = useAlerts()

    const [category, setCategory] = useState('')
    const [message, setMessage] = useState('')

    const [inProgress, setInProgress] = useState('')
    const [validationErrors, setValidationErrors] = useState({})
    const [isSent, setIsSent] = useState(false)


    // get selected category name
    const selectedCategory = !isNull(category) ? ticketsCategories?.find(i => i.slug === category) : {};


    const canNotSave = isNull(category) || isNull(message);


    // send request
    const saveNewTicket = async () => {

        setInProgress(true)
        setValidationErrors({})

        const data = {
            category,
            message,
        };

        const { is_ok } = await NewTicket(data);


        if (is_ok) {
            setIsSent(true)
        } else {
            addAlert(t('mssg_2'), 'error')
        }

        setInProgress(false)
    }

    return (
        <div className="w-full max-w-3xl mx-auto xl:p-8 lg:p-6 p-4">
            <div className="mb-3 max-lg:mt-3 pb-3">
                <h2 className="textHeader-s">{t("ticket_1")}</h2>
            </div>

            {isSent ? <SuccessMessage t={t} /> :
                isNull(selectedCategory) ? (
                    <SelectCategory
                        data={ticketsCategories}
                        setCategory={setCategory}
                        t={t}
                    />
                ) : (
                    <div className="flex flex-col gap-5 mb-10">
                        <label
                            className="flex flex-col gap-2">
                            <span className="textBody-s2 text-fontColor2">{t("ticket_2")} <span className="text-red-500">*</span></span>
                            <div
                                onClick={() => setCategory('')}
                                className={`bg-white textBody-s3 border shadow-sm focus:shadow px-4 py-2.5 rounded-md outline-none transition-colors duration-200 select-none cursor-pointer
                                ${validationErrors?.category ? 'border-red-500 border-solid' : 'border-dashed focus:border-solid border-borderColor hover:border-primary/20 focus:border-primary'}
                                `}>
                                {selectedCategory?.label}
                            </div>
                            {validationErrors?.category && <p className="text-red-500 textBody-xs3">{validationErrors?.category}</p>}
                        </label>

                        <label
                            className="flex flex-col gap-2">
                            <span className="textBody-s2 text-fontColor2">{t("ticket_3")} <span className="text-red-500">*</span></span>
                            <textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                type="text"
                                className={`bg-white border shadow-sm focus:shadow px-4 py-2.5 rounded-md outline-none transition-colors duration-200 resize-y min-h-60 h-60 max-h-96
                                ${validationErrors?.message ? 'border-red-500 border-solid' : 'border-dashed focus:border-solid border-borderColor hover:border-primary/20 focus:border-primary'}
                                `}>
                            </textarea>
                            {validationErrors?.message && <p className="text-red-500 textBody-xs3">{validationErrors?.message}</p>}
                        </label>

                        <button
                            disabled={inProgress || canNotSave}
                            onClick={saveNewTicket}
                            type="submit"
                            className={`mt-4 rounded-md px-4 lg:py-4 py-3 flex justify-center items-center textBody-s1 border-0 transition-colors duration-200
                            ${canNotSave ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'text-white bg-primary hover:bg-primary/90'}
                            `}>
                            {!inProgress
                                ? <span>{t("ticket_4")}</span>
                                : <Spinner className="w-5 h-5 animate-spin" />
                            }
                        </button>
                    </div>
                )}

        </div>
    )
}