import { useState } from "react";
import { debounce } from "../../Helpers/utils";
import ClientsList from "./ClientsList";
import Header from "./Header";
import { GetAllClientsBySalon } from "../../lib/api";
import { useTranslation } from "react-i18next";



export default function Clients() {

    const { t } = useTranslation()

    const [pageNumber, setPageNumber] = useState(1)
    const [searchQuery, setSearchQuery] = useState("")

    const paramsData = {
        ...(pageNumber !== 1 && { page: pageNumber }),
        ...(searchQuery && { search_query: searchQuery }),
    };

    const { data, loading } = GetAllClientsBySalon(paramsData);
    const { data: clients, total, current_page, last_page } = data?.data ?? {};


    // search filter
    const handleSearchFilter = debounce(txt => {
        setSearchQuery(txt)
    }, 500);

    return (
        <div className="bg-white min-h-dvh">
            <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 p-4">
                <div className="mb-3 max-lg:mt-3 pb-3 flex items-center justify-between">
                    <div className="flex flex-col gap-1">
                        <h2 className="flex items-center textHeader-l">{t("cl_01")}</h2>
                        <p className="textBody-s3">{t("cl_02")}</p>
                    </div>

                    {/* btn */}
                </div>


                <Header total={total} handleSearchFilter={handleSearchFilter} t={t} />

                <ClientsList
                    loading={loading}
                    clients={clients}
                    current_page={current_page}
                    last_page={last_page}
                    setPageNumber={setPageNumber}
                    t={t}
                />

            </div>
        </div>
    )
}