import { Link, useLocation } from "react-router-dom";
import { Appointments, Clients, Dashboard, Date, Help, Salon, Services, Settings, Share, } from "../../icons";
import { useTranslation } from "react-i18next";



export default function SideBar({ isOpen }) {

    const { t } = useTranslation()

    // Get path name
    const location = useLocation();
    const { pathname } = location;


    const asideLinks = [
        {
            label: t("dash_sidebar_1"),
            icon: <Dashboard className="w-6 h-6" />,
            path: '/',
        }, {
            //     label: 'Calendar',
            //     icon: <Calendar2 className="w-6 h-6" />,
            //     path: '/calendar',
            // }, {
            label: t("dash_sidebar_2"),
            icon: <Appointments className="w-6 h-6" />,
            path: '/appointments',
        }, {
            label: t("cl_01"),
            icon: <Clients className="w-6 h-6" />,
            path: '/clients',
        }, {
            label: t("dash_sidebar_3"),
            icon: <Salon className="w-6 h-6" />,
            path: '/salon',
        }, {
            label: t("dash_sidebar_4"),
            icon: <Share className="w-6 h-6" />,
            path: '/share',
        }, {
            label: t("dash_sidebar_6"),
            icon: <Date className="w-6 h-6" />,
            path: '/worktime',
        }, {
            label: t("dash_sidebar_8"),
            icon: <Services className="w-6 h-6" />,
            path: '/services',
        }, {
            label: t("dash_sidebar_7"),
            icon: <Settings className="w-6 h-6" />,
            path: '/settings',
        },
    ];


    const isActive = (path) => {
        // if (path === "/dashboard" && pathname === "/dashboard") {
        //     return true;
        // } else if (path !== "/" && pathname !== "/") {
        //     return pathname.startsWith(path);
        // }
        return path === pathname;
    }

    return (
        <aside className={`lg:flex hidden fixed flex-col justify-between h-[calc(100dvh_-_70px)] z-[100] textBody-s3 text-white scrollBar1 overflow-y-auto bg-sideBarBg p-3 ${isOpen ? 'w-[200px]' : 'w-[64px]'} transition-all duration-200`}>
            <div className="flex flex-col gap-2">
                {asideLinks.map((link, index) => {
                    return (
                        <Link to={`${link.path ?? '/'}`} key={index}>
                            <div className={`flex items-center gap-2 rounded-lg text-white transition-colors duration-200 cursor-pointer ${isActive(link.path) ? 'bg-primary' : 'bg-transparent hover:bg-primary'}`}>
                                <div className="flex shrink-0 justify-center items-center w-10 h-10">
                                    {link.icon}
                                </div>
                                {isOpen && <span className="whitespace-nowrap">{link.label}</span>}
                            </div>
                        </Link>
                    )
                })}
            </div>

            <div className="mt-4 flex flex-col gap-2">
                <Link to="/new-ticket" className="flex flex-col">
                    <div className={`flex items-center gap-2 rounded-lg text-white transition-colors duration-200 bg-transparent hover:bg-primary cursor-pointer`}>
                        <div className="flex shrink-0 justify-center items-center w-10 h-10">
                            <Help className="w-6 h-6" />
                        </div>
                        {isOpen && <span className="whitespace-nowrap">{t("dash_sidebar_5")}</span>}
                    </div>
                </Link>
            </div>
        </aside>
    )
}