import { useState } from "react";
import { PointsMenu } from "../../../icons";
import ModalLayout from "../../../layouts/ModalLayout";

export default function ServiceCardDropDown({ selectToEdit, removeService, isDisabled, t }) {

    const [isOpen, setIsOpen] = useState(false)
    const toggleDropDown = () => setIsOpen(!isOpen)

    return (
        <div className="relative flex justify-center">
            <button
                disabled={isDisabled}
                onClick={toggleDropDown}
                className="rounded-lg p-1 hover:bg-primary/5 text-fontColor2">
                <PointsMenu className="w-5 h-5" />
            </button>

            {isOpen && (
                <ModalLayout
                    wallBackground={`bg-black/20`}
                    className={`absolute ltr:right-0 rtl:left-0 z-[1012]`}
                    close={toggleDropDown}
                >
                    <div className="shadow-lg bg-white animation-01 animation-02 w-[200px] h-full rounded-lg overflow-hidden py-2 flex flex-col textBody-s3 text-fontColor2">
                        <button
                            onClick={() => {
                                toggleDropDown()
                                selectToEdit()
                            }}
                            className={`text-start whitespace-nowrap py-2.5 px-4 transition-colors duration-300 flex items-center justify-between hover:bg-primary/5`}>
                            <span>{t('services_09')}</span>
                        </button>
                        <button
                            onClick={() => {
                                toggleDropDown()
                                removeService()
                            }}
                            className={`text-start text-dangerColor whitespace-nowrap py-2.5 px-4 transition-colors duration-300 flex items-center justify-between hover:bg-primary/5`}>
                            <span>{t('services_10')}</span>
                        </button>
                    </div>
                </ModalLayout>
            )}
        </div>
    )
}