export const servicesCategories = [
    {
        id: "985673765",
        name: {
            en: "Hair Services",
            fr: "Services de Coiffure",
            ar: "خدمات الشعر",
        },
        sub_categories: [
            {
                id: "568357698",
                name: {
                    en: "Haircut",
                    fr: "Coupe de cheveux",
                    ar: "قص الشعر",
                },
                price: "50",
                currency: "dh",
                duration: "30",
            },
            {
                id: "678356875",
                name: {
                    en: "Hair Styling",
                    fr: "Coiffure",
                    ar: "تصفيف الشعر",
                },
                price: "70",
                currency: "dh",
                duration: "20",
            },
            {
                id: "378359343",
                name: {
                    en: "Hair Coloring",
                    fr: "Coloration des cheveux",
                    ar: "تلوين الشعر",
                },
                price: "200",
                currency: "dh",
                duration: "60",
            },
            {
                id: "376936579",
                name: {
                    en: "Hair Treatment",
                    fr: "Traitement capillaire",
                    ar: "علاج الشعر",
                },
                price: "150",
                currency: "dh",
                duration: "45",
            },
            {
                id: "923847502",
                name: {
                    en: "Keratin Treatment",
                    fr: "Traitement à la kératine",
                    ar: "علاج الكيراتين",
                },
                price: "350",
                currency: "dh",
                duration: "90",
            },
            {
                id: "847236482",
                name: {
                    en: "Scalp Massage",
                    fr: "Massage du cuir chevelu",
                    ar: "تدليك فروة الرأس",
                },
                price: "60",
                currency: "dh",
                duration: "20",
            },
        ],
    },
    {
        id: "678912345",
        name: {
            en: "Beard Services",
            fr: "Services de Barbe",
            ar: "خدمات اللحية",
        },
        sub_categories: [
            {
                id: "356857139",
                name: {
                    en: "Beard Trim",
                    fr: "Taille de barbe",
                    ar: "تشذيب اللحية",
                },
                price: "40",
                currency: "dh",
                duration: "20",
            },
            {
                id: "946824684",
                name: {
                    en: "Beard Styling",
                    fr: "Stylisation de la barbe",
                    ar: "تصفيف اللحية",
                },
                price: "60",
                currency: "dh",
                duration: "30",
            },
            {
                id: "847594302",
                name: {
                    en: "Beard Coloring",
                    fr: "Coloration de la barbe",
                    ar: "تلوين اللحية",
                },
                price: "100",
                currency: "dh",
                duration: "40",
            },
            {
                id: "958467238",
                name: {
                    en: "Hot Towel Shave",
                    fr: "Rasage avec serviette chaude",
                    ar: "حلاقة بالمنشفة الساخنة",
                },
                price: "70",
                currency: "dh",
                duration: "25",
            },
            {
                id: "983745839",
                name: {
                    en: "Beard Oil Treatment",
                    fr: "Traitement à l'huile pour la barbe",
                    ar: "علاج زيت اللحية",
                },
                price: "80",
                currency: "dh",
                duration: "20",
            },
        ],
    },
    {
        id: "129384756",
        name: {
            en: "Facial Services",
            fr: "Services de Soins Visage",
            ar: "خدمات الوجه",
        },
        sub_categories: [
            {
                id: "238746298",
                name: {
                    en: "Facial Cleanup",
                    fr: "Nettoyage du visage",
                    ar: "تنظيف الوجه",
                },
                price: "120",
                currency: "dh",
                duration: "45",
            },
            {
                id: "937462839",
                name: {
                    en: "Face Massage",
                    fr: "Massage du visage",
                    ar: "تدليك الوجه",
                },
                price: "80",
                currency: "dh",
                duration: "30",
            },
            {
                id: "672384756",
                name: {
                    en: "Charcoal Facial Mask",
                    fr: "Masque facial au charbon",
                    ar: "قناع الفحم للوجه",
                },
                price: "150",
                currency: "dh",
                duration: "40",
            },
            {
                id: "937485629",
                name: {
                    en: "Skin Hydration Treatment",
                    fr: "Traitement hydratation peau",
                    ar: "علاج ترطيب البشرة",
                },
                price: "200",
                currency: "dh",
                duration: "50",
            },
        ],
    },
    {
        id: "982345678",
        name: {
            en: "Additional Services",
            fr: "Services Supplémentaires",
            ar: "خدمات إضافية",
        },
        sub_categories: [
            {
                id: "847362839",
                name: {
                    en: "Eyebrow Shaping",
                    fr: "Soin des sourcils",
                    ar: "تشكيل الحواجب",
                },
                price: "50",
                currency: "dh",
                duration: "15",
            },
            {
                id: "284756938",
                name: {
                    en: "Ear and Nose Waxing",
                    fr: "Épilation oreilles et nez",
                    ar: "تنظيف الأنف والأذنين بالشمع",
                },
                price: "70",
                currency: "dh",
                duration: "20",
            },
            {
                id: "937465839",
                name: {
                    en: "Full Grooming Package",
                    fr: "Forfait complet",
                    ar: "حزمة العناية الكاملة",
                },
                price: "400",
                currency: "dh",
                duration: "120",
            },
        ],
    },
];
