import { useEffect, useState } from "react";
import { Hide1 } from "../../../icons";
import ModalLayout from "../../../layouts/ModalLayout";
import { n } from "../../../Helpers/utils";


export default function UpdateService({ service, close, handleUpdateService, t }) {

    const { category_id, id } = service;

    const [duration, setDuration] = useState(0)
    const [price, setPrice] = useState(0)


    useEffect(() => {
        setDuration(service?.duration)
        setPrice(service?.price)
    }, [service])

    const save = () => {
        handleUpdateService(category_id, id, duration, price)
        close()
    }

    return (
        <ModalLayout
            wallBackground={'bg-black/80'}
            className="fixed lg:max-w-[550px] w-full -translate-x-2/4 lg:-translate-y-2/4 left-2/4 lg:top-2/4 z-[1011] max-lg:bottom-0"
            close={close}
        >
            <div className={`bg-white textBody-s3 w-full lg:rounded-lg gap-1 justify-between flex flex-col overflow-hidden rounded-t-xl`}>
                <div className="flex items-center justify-between p-4">
                    <h3 className="textBody-m1">{t('services_11')}</h3>
                    <button
                        onClick={close}
                        className="rounded-lg p-1 hover:bg-primary/5">
                        <Hide1 className="w-5 h-5" />
                    </button>
                </div>

                <div className="py-6 px-4 flex flex-col gap-4 border-y border-primaryBorder lg:max-h-[60vh] max-lg:h-full overflow-y-auto">
                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t('services_12')}</span>
                        <input
                            type="text"
                            className={`p-3 rounded-lg transition-colors duration-300 border border-primaryBorder/60 hover:border-primaryBorder/90 outline-primary/80`}
                            // onChange={(e) => setName(e.target.value)}
                            value={t(`service_${id}`)}
                            readOnly
                            disabled
                        />
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t('services_13')}</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden border border-borderColor hover:border-primary/80`}>
                            <input
                                type="number"
                                className={`w-full p-3 outline-none`}
                                onChange={(e) => setDuration(n(e.target.value))}
                                value={duration}
                            />
                            <div className="flex items-center justify-center w-24 ltr:border-l rtl:border-r border-borderColor text-fontColor2 select-none">{t('services_17')}</div>
                        </div>
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t('services_14')}</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden border border-borderColor hover:border-primary/80`}>
                            <input
                                type="number"
                                className={`w-full p-3 outline-none`}
                                onChange={(e) => setPrice(n(e.target.value))}
                                value={price}
                            />
                            <div className="flex items-center justify-center w-24 ltr:border-l rtl:border-r border-borderColor text-fontColor2 select-none">{t('services_18')}</div>
                        </div>
                    </label>
                </div>

                {/* footer */}
                <div className="pt-2 pb-3 px-4 flex items-center gap-2">
                    <button
                        onClick={save}
                        className={`bg-primary border border-primary text-white hover:bg-primary/90 hover:border-primary/90 rounded-md py-2.5 px-6 transition-all duration-100 ease-in flex items-center justify-center gap-2 textBody-s1`}>
                        {t('services_15')}
                    </button>
                    <button
                        onClick={close}
                        className={`bg-transparent border border-primaryBorder text-secondaryTextColor hover:bg-secondaryTextColor/5 rounded-md py-2.5 px-6 transition-all duration-100 ease-in flex items-center justify-center gap-2 textBody-s2`}>
                        {t('services_16')}
                    </button>
                </div>
            </div>
        </ModalLayout>
    )
}