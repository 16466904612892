import ServiceCardDropDown from "./ServiceCardDropDown";


export default function ServiceCard({ category_id, service, t, setSelectedServiceToEdit, handleRemoveService, isDisabled }) {

    const { id, duration, price } = service;

    const selectToEdit = () => {
        if (isDisabled) return;
        setSelectedServiceToEdit({ category_id, ...service })
    }

    const removeService = () => {
        handleRemoveService(category_id, id)
    }

    return (
        <tr className={`md:[&>*]:px-4 [&>*]:px-3 md:[&>*]:py-5 [&>*]:py-4 border-t border-primary/10 cursor-pointer select-none transition-all duration-200 ease-in bg-white ${isDisabled ? '' : 'hover:bg-primary/[0.03]'}`}>
            <td onClick={selectToEdit}>
                <div className="flex flex-col">
                    <h1 className="textBody-m1 lg:line-clamp-1 text-fontColor">
                        {t(`service_${id}`)}
                    </h1>
                    <span className="md:hidden block textBody-s3 text-fontColor2">
                        {t('services_02', { minutes: duration })}
                    </span>
                    <span className="md:hidden block textBody-s3 text-fontColor2">
                        {t('services_01', { price })}
                    </span>
                </div>
            </td>
            <td onClick={selectToEdit} className="md:block hidden">
                <span className="textBody-xs3 text-fontColor2">
                    {t('services_02', { minutes: duration })}
                </span>
            </td>
            <td onClick={selectToEdit} className="ltr:text-right rtl:text-left">
                <span className="md:block hidden textBody-s2 text-fontColor">
                    {t('services_01', { price })}
                </span>
            </td>
            <td className="w-[40px]" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <ServiceCardDropDown selectToEdit={selectToEdit} removeService={removeService} isDisabled={isDisabled} t={t} />
            </td>
        </tr>
    );
}