import { useState } from "react";
import { AddNew1, Check, Hide1, Spinner } from "../../../icons";
import ModalLayout from "../../../layouts/ModalLayout";
import { servicesCategories } from "../../../db/services";
import i18next from "i18next";
import { isNull } from "../../../Helpers/utils";


export default function SelectServices({ t, isDisabled, isServiceSelected, handleSelectService, inProgress }) {

    const [isOpen, setIsOpen] = useState(false)
    const toogleModal = () => setIsOpen(!isOpen)

    const currentLang = i18next.language ?? "en";


    // State to store countries filter value
    const [filterSearchValue, setFilterSearchValue] = useState("")
    const [filteredServices, setFilteredServices] = useState(servicesCategories)
    const handleFilterServices = (val = "") => {
        setFilterSearchValue(val);

        if (!val) {
            setFilteredServices(servicesCategories);
        } else {
            const filtered = servicesCategories.map((category) => {
                // Filter subcategories based on the search value
                const filteredSubCategories = category.sub_categories.filter((sub) => {
                    return (
                        sub.name.en.toLowerCase().includes(val.toLowerCase()) ||
                        sub.name.fr.toLowerCase().includes(val.toLowerCase()) ||
                        sub.name.ar.includes(val)
                    );
                });

                // Include category only if it has matching subcategories or its name matches
                const isCategoryMatch =
                    category.name.en.toLowerCase().includes(val.toLowerCase()) ||
                    category.name.fr.toLowerCase().includes(val.toLowerCase()) ||
                    category.name.ar.includes(val);

                if (isCategoryMatch || filteredSubCategories.length > 0) {
                    return {
                        ...category,
                        sub_categories: filteredSubCategories,
                    };
                }

                return null;
            }).filter(Boolean); // Remove null categories

            setFilteredServices(filtered);
        }
    };



    return (
        <>
            <button
                onClick={toogleModal}
                disabled={isDisabled}
                className="bg-primary hover:bg-primary/80 border-[1.5px] border-primary hover:border-primary/80 text-white rounded-lg lg:py-3 py-2 lg:px-6 px-2 transition-all duration-100 ease-in active:scale-[.97] flex items-center gap-2 textBody-m1">
                <span className="lg:block hidden">{t('services_07')}</span>
                <AddNew1 className="w-4 h-4" />
            </button>

            {isOpen &&
                <ModalLayout
                    wallBackground={'bg-white'}
                    className="fixed w-full left-0 top-0 z-[1011] h-dvh overflow-y-auto"
                    close={toogleModal}
                >
                    <div className={`textBody-s3 pb-20`}>
                        <div className="w-full sticky top-0 bg-white border-b border-borderColor z-50">
                            <div className="w-full max-w-5xl mx-auto flex items-center justify-between px-4 py-4">
                                <h3 className="textBody-ml1">{t('services_03')}</h3>
                                <div className="flex items-center gap-2">
                                    {inProgress && <Spinner className="w-4 h-4 animate-spin text-primary" />}
                                    <button
                                        onClick={toogleModal}
                                        className="rounded-lg p-1 hover:bg-primary/5">
                                        <Hide1 className="w-5 h-5" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="w-full max-w-5xl mx-auto pt-5 pb-4 px-4">
                            <input
                                type="test"
                                placeholder={t('services_04')}
                                className="px-4 py-3 w-full bg-borderColor/40 rounded-md outline-none"
                                value={filterSearchValue}
                                onChange={(e) => handleFilterServices(e.target.value?.trim()?.toLowerCase())}
                            />
                        </div>

                        <div className="w-full flex flex-col gap-4">
                            {!isNull(filteredServices) &&
                                filteredServices?.map((category) => {
                                    const { id, sub_categories, name } = category;
                                    return (
                                        <div key={id} className="w-full max-w-5xl mx-auto px-4">
                                            <h1 className="textBody-m1 line-clamp-1 text-fontColor mb-4 mt-6">{name[currentLang]}</h1>
                                            <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 lg:gap-4 md:gap-3 gap-2 select-none">
                                                {sub_categories?.map((sub_category) => {

                                                    const { id: num, price, duration, name, currency } = sub_category;

                                                    const isSelected = isServiceSelected(num);

                                                    return (
                                                        <div
                                                            key={num}
                                                            onClick={() => {
                                                                if (!isSelected && !inProgress) {
                                                                    handleSelectService(id, num, price, duration, currency)
                                                                }
                                                            }}
                                                            className={`bg-white pt-4 pb-5 px-4 rounded-md border border-borderColor transition duration-200 relative ${isSelected || inProgress ? 'cursor-context-menu' : 'cursor-pointer hover:border-primary/5 hover:bg-primary/[0.03]'}`}>
                                                            <div className="flex flex-col gap-1">
                                                                <h1 className="textBody-m1 text-fontColor">{name[currentLang]}</h1>
                                                                <span className="textBody-s3 text-fontColor2">{t('services_02', { minutes: duration })}</span>
                                                                <span className="textBody-s3 text-fontColor2">
                                                                    {t('services_01', { price: price })}
                                                                </span>
                                                            </div>

                                                            {isSelected &&
                                                                <div className="absolute md:top-4 top-2 md:ltr:right-4 ltr:right-2 md:rtl:left-4 rtl:left-2 border border-green-500 bg-green-400 text-white shadow-sm p-[1px] rounded-full">
                                                                    <Check className="lg:w-4 w-3.5 lg:h-4 h-3.5" />
                                                                </div>}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </ModalLayout>
            }
        </>
    )
}