import { useTranslation } from "react-i18next";


export default function ActionsDropDown({ status }) {

    const { t } = useTranslation()

    return (
        <div className="relative inline-block ltr:dir-ltr rtl:dir-rtl">
            <div className="flex items-center gap-2 ltr:ml-2 rtl:mr-2">
                <button
                    className={`lg:px-3.5 px-2.5 py-2 rounded-md lg:textBody-xs2 textBody-2xs2 transition ${renderStatusBadge(status)}`}>
                    {t(renderAppoStatusName(status))}
                </button>
            </div>
        </div>
    )
}

const renderStatusBadge = (status) => {
    switch (status) {
        case 'active':
            return "bg-green-100 text-green-500 border border-green-200/50 hover:border-green-200";
        case 'confirmed':
            return "bg-blue-100 text-blue-500 border border-blue-200/50 hover:border-blue-200";
        case 'canceled':
            return "bg-red-100 text-red-500 border border-red-200/50 hover:border-red-200";
        case 'completed':
            return "bg-gray-100 text-gray-500 border border-gray-200/50 hover:border-gray-200";
        default:
            return "bg-gray-100 text-gray-500 border border-gray-200/50 hover:border-gray-200";
    }
};

const renderAppoStatusName = (stts) => {
    switch (stts) {
        case 'active':
            return "dash_appo_8";
        case 'confirmed':
            return "dash_appo_9";
        case 'canceled':
            return "dash_appo_11";
        case 'completed':
            return "dash_appo_10";
        default:
            return "";
    }
}